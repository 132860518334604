/*
 Ajax filtering scripts
 */
jQuery(document).ready(function($) {

    /*-----------------------------------------------------------------------------------
     Blog filtering, isotope, infinite scrolling
     http://www.creativebloq.com/web-design/filter-web-content-infinite-scroll-10134808
     -----------------------------------------------------------------------------------*/
    var ajaxFiltering;

    ajaxFiltering = {

        init: function () {

            // Setup variables
            ajaxFiltering.pageholding = $('.masonry-holding');
            ajaxFiltering.pagewrapper = $('.masonry');
            ajaxFiltering.filters = $('.post-filter');
            ajaxFiltering.pagination = '.load-more a';

            // Disable footer lock functionality
            $('.site-footer').addClass('stick-me');

            // Once images are loaded run Isotope
            ajaxFiltering.isotope();

            // Bind filtering event
            ajaxFiltering.filters.find('li').not('.ignore').on('click', ajaxFiltering.filter);

            // Delegate the click event to work with ajaxed content see (http://api.jquery.com/live/)
            $(document).on('click', ajaxFiltering.pagination, ajaxFiltering.loadMore);

        },

        isotope:function() {

            // Wait for images to be loaded
            ajaxFiltering.pageholding.imagesLoaded(function() {

                // init isotope, then insert all items from hidden .alpha
                ajaxFiltering.pagewrapper.isotope({
                    columnWidth: '.element',
                    itemSelector: '.element',
                    resizable: false,
                    transitionDuration: '0.8s'
                }).isotope('insert', ajaxFiltering.pageholding.find('.element'));

                ajaxFiltering.pagewrapper.trigger('complete');

            });
        },

        filter:function(e) {

            e.preventDefault();

            var $this = $(this);

            // Don't filter if link is the same as current category
            if (!$this.hasClass('active')) {

                // Remove previous active class from filter
                ajaxFiltering.filters.find('.active').removeClass('active');

                // Add active class to current filter
                $this.addClass('active');

                // Get the url of clicked link
                var $url = $this.find('a').attr('href');

                $.get($url, function($data) {

                    // Get title of ajaxed page
                    ajaxFiltering.docTitle = $data.match(/<title>(.*?)<\/title>/)[1].trim();

                    // Update page title to match ajaxed title
                    document.title = ajaxFiltering.docTitle;

                    // Add url to history state
                    if (history.pushState) {
                        history.pushState('', ajaxFiltering.docTitle, $url);
                    }

                    var pageTitle = $($data).find('.page-title').html();
                    $('.page-title').text(pageTitle);

                    $data = $($data).find('#content-area');

                    $data.imagesLoaded(function() {

                        // Replace the pagination
                        var $newpagination = $data.find('.load-more');

                        if (!$newpagination || $newpagination.length === 0) {
                            $newpagination =  '';
                        }

                        $('.load-more').remove();
                        $('#content-area').append($newpagination);

                        ajaxFiltering.pagewrapper.isotope('remove', $('.element'));

                        // Update the content
                        var $new =  $data.find('.element');
                        ajaxFiltering.pagewrapper.isotope('insert', $new);

                    });

                });

            }

        },

        loadMore:function(e) {

            e.preventDefault();

            // Get url of clicked link
            var $url = $('.load-more a').attr('href');

            $.get($url, function($data) {

                $data = $($data).find('#content-area');

                $data.imagesLoaded(function() {

                    // Replace the pagination
                    var $newpagination = $data.find('.load-more');

                    if (!$newpagination || $newpagination.length === 0) {
                        $newpagination =  '';
                    }

                    $('.load-more').remove();
                    $('#content-area').append($newpagination);

                    // Add the new elements to isotope
                    var $new =  $data.find('.element');
                    ajaxFiltering.pagewrapper.isotope('insert', $new);

                });

            });
        }

    };

    ajaxFiltering.init();

    // update columnWidth on window resize
    $(window).smartresize(function() {
        ajaxFiltering.isotope();
    });

});
