/*
 Ajax Overlay scripts
 */
jQuery(document).ready(function($) {

    var ajaxOverlay;

    ajaxOverlay = {

        init: function () {

            ajaxOverlay.overlayState = 'closed';
            ajaxOverlay.startPage = document.URL;
            ajaxOverlay.startTitle = document.title;
            ajaxOverlay.windowHeight = $(window).height();

            $('.overlay-link').bind('click', ajaxOverlay.openOverlay);
        },

        openOverlay:function() {

            // Used to prevent duplicate overlays coming in
            if (ajaxOverlay.overlayState === 'open') {
                return false;
            }

            ajaxOverlay.overlayState = 'open';

            var $this = $(this);

            // Get the url of clicked link
            var $url = $this.attr('href');

            $.get($url, function($data) {

                // Get title of destination page
                ajaxOverlay.docTitle = $data.match(/<title>(.*?)<\/title>/)[1].trim();
                ajaxOverlay.bodyClasses = $data.match(/body class="(.*?)"/)[1].trim();

                // Decode any HTML entities that appear
                ajaxOverlay.docTitle = ajaxOverlay.docTitle;

                // Update page title to match destination title
                document.title = ajaxOverlay.docTitle;

                // Add url to history state
                if (history.pushState) {
                    history.pushState('', ajaxOverlay.docTitle, $url);
                }

                $data = $($data).find('#content article');

                function openOverlay() {
                    setTimeout(function() {

                        $('html').addClass('overlay-open');

                        // Close modal when escape key is pressed
                        $(document).bind('keyup.overlay', function(event) {

                            // 27 == "esc"
                            if (event.which === 27) {
                                ajaxOverlay.closeOverlay();
                                // unbind the event
                                $(document).unbind('keyup.overlay');
                            }

                        });

                        $('.close-overlay').bind('click', ajaxOverlay.closeOverlay);

                    }, 900);
                }

                $data.imagesLoaded(function() {

                    // Pretty ugly chunck of HTML, could use tidying up
                    $('body').append('<div class="overlay ' + ajaxOverlay.bodyClasses + '"><div class="site container-fluid"><div class="row"><div class="col-md-10 col-md-offset-1"><div class="close-overlay"><a href="#">&larr; Back</a></div><div class="overlay-content"></div><div class="close-overlay"><a href="#">&larr; Back</a></div></div></div></div></div>');

                    $('.overlay-content').append($data);

                    // Measure the height of the overlay
                    ajaxOverlay.overlayHeight = $('.overlay').height();

                    // Get the largest between overlay height and window height
                    ajaxOverlay.heightOffset = Math.max(ajaxOverlay.overlayHeight, ajaxOverlay.windowHeight);

                    // Move the overlay so that the bottom of the overlay touches top of the window
                    $('.overlay').css('top', -ajaxOverlay.heightOffset).delay(100).queue(function() {

                        $('.overlay').addClass('overlay-transition');

                        // Fade / move in overlay
                        $('.overlay').css({
                            'top': 0,
                            'opacity': 1
                        });

                        $('.overlay .site').css({
                            'min-height': ajaxOverlay.heightOffset
                        });

                        openOverlay();

                    });


                });

            });

            return false;

        },

        closeOverlay:function(e) {

            if (e) {
                e.preventDefault();
            }

            $('.overlay').css({ 'height': ajaxOverlay.windowHeight });

            $('html').removeClass('overlay-open');

            function closeOverlay() {
                setTimeout(function() {
                    $('.overlay').remove();
                }, 1000);

                $('.overlay').delay(100).css({ 'top': -ajaxOverlay.heightOffset, 'opacity': 0 });

                ajaxOverlay.overlayState = 'closed';
            }

            closeOverlay();

            document.title = ajaxOverlay.startTitle;

            // Add url to history state
            if (history.pushState) {
                history.pushState('', ajaxOverlay.startTitle, ajaxOverlay.startPage);
            }

        }

    };

    ajaxOverlay.init();

    // update columnWidth on window resize
    $(window).resize(function() {

        // Remeasure the height of the viewport when browser is resized
        ajaxOverlay.windowHeight = $(window).height();

        // Recheck the largest between overlay height and window height
        ajaxOverlay.heightOffset = Math.max(ajaxOverlay.overlayHeight, ajaxOverlay.windowHeight);

        $('.overlay .site').css({
            'min-height': ajaxOverlay.heightOffset
        });

    });

});
