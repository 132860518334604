/**
 * Main scripts file
 *
 * This file should contain any js scripts you want to add to the site.
 * Instead of calling it in the header or throwing it inside wp_head()
 * this file will be called automatically in the footer so as not to
 * slow the page load.
 *
 * @author O B L / Q U E
 * @package WordPress
 * @subpackage MBO_Framework
 * @since MBO Framework 1.0.0
 */


// Setup device variables based on screen width
// http://stackoverflow.com/a/16314034
function getStylesheetBreak() {
    if (Modernizr.mq('(min-width: 1140px)')) {
        return 'screen-lg';
    }
    else if (Modernizr.mq('(min-width: 992px)')) {
        return 'screen-md';
    }
    else if (Modernizr.mq('(min-width: 768px)')) {
        return 'screen-sm';
    }
    return 'screen-xs';
}

function getDisplayType() {
    if (Modernizr.mq('(min-width: 1025px)')) {
        return 'desktop';
    }
    else if (Modernizr.mq('(min-width: 768px)')) {
        return 'tablet';
    }
    return 'mobile';
}

getStylesheetBreak();
getDisplayType();

$(window).resize(function() {
    getStylesheetBreak();
    getDisplayType();
});

/**
 * DOM-based Routing
 *
 * Fire javascript based on body class (working off strictly WordPress body_class)
 * or put javascript in  common for executing on all pages.
 *
 * Several of the WordPress body classes include hyphens in the name, for these
 * cases replace the hyphen in the name with an underscore and this script will
 * handle the rest
 *
 * @link http://paulirish.com/2009/markup-based-unobtrusive-comprehensive-dom-ready-execution/
 *
 * @author Paul Irish (paulirish.com)
 * @since MBO Framework 1.0.0
 */
(function($) {

    var frameworkNS = {

        // All pages
        'common': {
            init: function () {

                // Sample Modernizr checks
                // ========================

                // Touch events
                if (!Modernizr.touchevents) {
                    console.log('No touch events');
                }

                // Media queries
                var mediaQuery = Modernizr.mq('(min-width: 900px)');
                if (mediaQuery) {
                    console.log('The browser window is larger than 900px');
                }

                // Fire up the newsletter notification bar
                $('.newsletter-notification').cookieBar({ closeButton : '.close-notification' });

                //
                // Setup body element for page scrolling
                // based on browser
                // ==========================================
                var isSafari = navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0;
                var bodyElement;

                if (isSafari) {
                    bodyElement = $('body');
                } else {
                    bodyElement = $('html, body');
                }

                // Setup variables
                var windowHeight;
                var windowWidth;

                function setBaseVariables() {
                    windowHeight = $(window).innerHeight();
                    windowWidth = $(window).innerWidth();
                }

                setBaseVariables();

                // Update variables on resize
                $(window).on('resize', function () {
                    setBaseVariables();
                });

                //
                // Navbar scrollspy
                // ==========================================

                // Get the headers position from the top of the page, plus its own height
                function checkOffset() {
                    var siteMainOffset = $('.site-main').position().top - $('#masthead').height() - 1;

                    if ($(window).scrollTop() > siteMainOffset) {
                        $('body').removeClass('navbar-transparent');
                    } else {
                        $('body').addClass('navbar-transparent');
                    }
                }

                // Do this on load just in case the user starts half way down the page
                checkOffset();

                // On Scroll
                $(window).scroll(function() {
                    checkOffset();
                });

                // Page height might change
                $(window).on('resize', function () {
                    checkOffset();
                });

                // Animate scroll to on secondary nav links
                $('.scroll-to').on('click touchstart', function(event) {
                    event.preventDefault();
                    var section = $(this).attr('href');
                    var scrollOffset = $('#masthead').height();
                    bodyElement.animate({
                        scrollTop: $(section).offset().top - scrollOffset
                    }, 1200, 'easeInOutExpo');

                    return false;
                });

                //
                // Sliders
                // ==========================================

                // Homepage tweets slider
                $('.tp-recent-tweets').flexslider({
                    smoothHeight: true,
                    animation: 'slide',
                    animationLoop: true,
                    animationSpeed: 600,
                    slideshowSpeed: 8000,
                    directionNav: false
                });

                // Product codes slider
                $('.product-codes .flexslider').flexslider({
                    slideshow: false,
                    manualControls: '.custom-control-nav li',
                    smoothHeight: true,
                    animation: 'fade',
                    animationLoop: true,
                    animationSpeed: 600,
                    slideshowSpeed: 8000,
                    directionNav: false,
                    touch: false
                });

                // Testimonials slider
                $('.testinoials-slider').flexslider({
                    smoothHeight: true,
                    animation: 'slide',
                    animationLoop: true,
                    animationSpeed: 600,
                    slideshowSpeed: 8000,
                    directionNav: false
                });

                //
                // Forms
                // ==========================================

                // Add placeholder class to all select inputs
                $('.gfield select').each(function() {

                    var optionClass = $(this).find('option:selected').attr('class');

                    if (optionClass) {
                        $(this).addClass('gf_placeholder');
                    } else if (!optionClass && $(this).hasClass('gf_placeholder')) {
                        $(this).removeClass('gf_placeholder');
                    }

                });

                $('.gfield select').change(function() {

                    var personType = $(this).val();
                    // Only update when the "i am a ..." dropdown is changed
                    if ($(this).attr('name') === 'input_3') {
                        $(this).closest('form').find(':submit').attr('onclick', function(i, v) {
                            return v.replace(/GF Form Click.*/, 'GF Form Click ' + personType);
                        });
                    }

                    var optionClass = $(this).find('option:selected').attr('class');

                    if (optionClass) {
                        $(this).addClass('gf_placeholder');
                    } else if (!optionClass && $(this).hasClass('gf_placeholder')) {
                        $(this).removeClass('gf_placeholder');
                    }

                });

                //
                // Pseudo touch event thingy
                // ==========================================
                $('.touch-hover').bind('touchstart touchend', function() {
                    $(this).toggleClass('touch-hover-effect');
                });

                //
                // Hacky disable of click closing dropdown in nav
                // ==========================================
                // Touch events
                if (!Modernizr.touchevents) {
                    $('#menu-primary-navigation-1 .dropdown-toggle').on('click touchstart', function() {
                        return false;
                    });
                }

            }
        }

    };

    // You can ignore this part, this is used to check and fire the above based on the body class
    var UTIL = {
        fire: function (func, funcname, args) {
            var namespace = frameworkNS;
            funcname = (funcname === undefined) ? 'init' : funcname;
            if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {

            UTIL.fire('common');

            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
            });

            UTIL.fire('common', 'finalise');
        }
    };

    $(document).ready(UTIL.loadEvents);

})(jQuery);