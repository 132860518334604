// Off Canvas Nav Module

/* jshint ignore:start */
var fullscreenVideoSlider = {

    init: function() {

        // Setup fullpage slider config
        config = {
            current: 1, // Number of current slide
            delay: 2000, // Slide delay in ms
            slideDelay: 5000, // Number of current slide
            total: $('.video-slide').length // Number of current slide
        };

        // Store videos in here
        video = {};

        // If there the video slider is present then go go go!
        if ($('.video-slider')[0]) {

            if (Modernizr.touchevents) {
                console.log('images');
                fullscreenVideoSlider.startImageSlider();
            } else {
                console.log('video');
                fullscreenVideoSlider.startVideoSlider();
            }

        }

    },
    startImageSlider: function() {

        $('#slide-' + config.current).addClass('active');

        setTimeout(function() {

            $('#slide-' + config.current).removeClass('active');

            if (config.current === config.total) {
                config.current = 1;
                fullscreenVideoSlider.startImageSlider();
            } else {
                config.current++;
                fullscreenVideoSlider.startImageSlider();
            }

        }, config.slideDelay);

    },
    startVideoSlider: function() {


      // If undefined then we are on the first run through on the Video so we need to initialise it!
      if ( typeof video['player' + config.current ]  === 'undefined' ) {

        video['player' + config.current ] = videojs('video-' + config.current, {

          /* Video options */
          controls: false,
          preload: "auto",
          children: {
            posterImage: false,
            textTrackDisplay: false,
            loadingSpinner: false,
            bigPlayButton: false,
            controlBar: false,
          },

        }, function() {

          // When ready show the video and play it
          $('#slide-' + config.current).addClass('active');
          this.play();

          // Setup event handeler for this video (only needs to happen on the first run through)
          this.one('ended', function() {

            setTimeout( function(){

              $('#slide-' + config.current).removeClass('active');

              video['player' + config.current ].pause().currentTime(0);

              if(config.current === config.total) {
                config.current = 1;
                fullscreenVideoSlider.startVideoSlider();
              } else {
                config.current++;
                fullscreenVideoSlider.startVideoSlider();
              }

            }, config.delay);

          });

        });


      } else { // Video is already initialised so we need to rewind it and play from the start!

          // When ready show the video and play it
          $('#slide-' + config.current).addClass('active');
          video['player' + config.current ].play();

      }

    }

};

$( document ).ready( fullscreenVideoSlider.init );
/* jshint ignore:end */
