/**
 * Utility functions
 *
 * @author O B L / Q U E
 * @package WordPress
 * @subpackage MBO_Framework
 * @since MBO Framework 1.0.0
 */

// Stop scrollTo animation if the user scrolls. Defaults on .stop() should be fine
// http://stackoverflow.com/questions/8858994/let-user-scrolling-stop-jquery-animation-of-scrolltop
jQuery('html, body').bind('scroll mousedown DOMMouseScroll mousewheel keyup', function(e) {
    if (e.which > 0 || e.type === 'mousedown' || e.type === 'mousewheel') {
        jQuery('html, body').stop().unbind('scroll mousedown DOMMouseScroll mousewheel keyup'); // This identifies the scroll as a user action, stops the animation, then unbinds the event straight after (optional)
    }
});


/**
 * Debounced window resize script
 *
 * Fires the contained scripts only at the end of the page resize.
 *
 *     USAGE:
 *     $(window).smartresize(function(){
 *         // code that takes it easy...
 *     });
 *
 * @link http://www.paulirish.com/2009/throttled-smartresize-jquery-event-handler/
 *
 * @author Paul Irish (paulirish.com)
 * @since MBO Framework 1.0.0
 */
(function($, sr) {

    // debouncing function from John Hann
    // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
    var debounce = function (func, threshold, execAsap) {
        var timeout;

        return function debounced() {
            var obj = this;
            var args = arguments;
            function delayed () {
                if (!execAsap) {
                    func.apply(obj, args);
                }
                timeout = null;
            }

            if (timeout) {
                clearTimeout(timeout);
            }
            else if (execAsap) {
                func.apply(obj, args);
            }

            timeout = setTimeout(delayed, threshold || 100);
        };
    };

    // smartresize
    jQuery.fn[sr] = function(fn) { return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); };

})(jQuery,'smartresize');
