// Off Canvas Nav Module
var offCanvasNav = {

    init: function() {

        // Setup nav config
        offCanvasNav.config = {
            bodyEl: $('body'),
            openbtn: $('#open-button'),
            closebtn: $('#close-button'),
            dropdownToggle: $('.off-canvas-nav .menu-item-has-children > a'),
            isOpen: false,
            windowWidth: $(window).width()
        };

        // Initialise event handlers
        offCanvasNav.initEvents();

    },

    initEvents: function() {

        // Clicking the open button toggles the menu
        offCanvasNav.config.openbtn.on('click', offCanvasNav.toggleMenu);

        // Clicking the close button toggles the menu
        offCanvasNav.config.closebtn.on('click', offCanvasNav.toggleMenu);

        // Clicking the close button toggles the menu
        offCanvasNav.config.dropdownToggle.on('click', offCanvasNav.toggleDropdown);

        // Close nav on window width resize
        // Done by width to prevent iphone minimal ui closing the nav
        $(window).resize(function() {
            if ($(this).width() !== offCanvasNav.config.windowWidth && offCanvasNav.config.isOpen) {

                // Save new width
                offCanvasNav.config.windowWidth = $(this).width();

                offCanvasNav.toggleMenu();

            }
        });

    },

    toggleMenu: function(e) {

        if (e) {
            e.preventDefault();
        }

        if (offCanvasNav.config.isOpen) {
            offCanvasNav.config.bodyEl.removeClass('show-mobile-menu');
        } else {
            offCanvasNav.config.bodyEl.addClass('show-mobile-menu');
        }

        offCanvasNav.config.isOpen = !offCanvasNav.config.isOpen;

    },

    toggleDropdown: function(e) {

        if (e) {
            e.preventDefault();
        }

        var dropdownParent = $(this).closest('.menu-item-has-children');
        var dropdownParentUl = $(this).closest('ul');

        if (dropdownParent.hasClass('open')) {

            dropdownParent.find('> .sub-menu').slideUp();
            dropdownParent.removeClass('open');

        } else {

            dropdownParentUl.find('> .menu-item-has-children.open > .sub-menu').slideUp();
            dropdownParentUl.find('> .menu-item-has-children.open').removeClass('open');

            dropdownParent.find('> .sub-menu').slideDown();
            dropdownParent.addClass('open');

        }

    }


};

$(document).ready(offCanvasNav.init);
